import { useState } from "react"

import { PlateOrderError } from "src/components/order/MountingPlateStore/MountingPlateOrderWizard/PlateOrderError"
import { usePostMountingPlateOrder } from "src/data/plateStore/queries/plateStoreQueries"
import { TPlateStorePostBody } from "src/data/plateStore/types/plateStoreTypes"

export function useMountingPlateOrder({
  onSuccess,
}: {
  onSuccess: () => void
}) {
  const [plateOrderError, setPlateOrderError] = useState<React.ReactNode>()
  const postMountingPlateOrder = usePostMountingPlateOrder()

  function handleSubmitPlateOrder(body: TPlateStorePostBody) {
    postMountingPlateOrder.mutate(body, {
      onError: () => setPlateOrderError(<PlateOrderError />),
      onSuccess,
    })
  }

  return {
    plateOrderError,
    handleSubmitPlateOrder,
    ...postMountingPlateOrder,
  }
}
