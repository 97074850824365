import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import { spacing } from "src/ui/spacing"

export function PlateOrderError() {
  const { t, langKeys } = useTranslate()

  return (
    <ErrorBox>
      {t(langKeys.failed_general_error_title)}{" "}
      {t(langKeys.failed_contact_support)}
    </ErrorBox>
  )
}

const ErrorBox = styled.div`
  display: grid;
  gap: ${spacing.M};
`
