import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { Show } from "src/ui/Show/Show"
import { spacing } from "src/ui/spacing"

export function MountingPlateOrderConfirmationStep({
  nbrPlates,
  formattedCost,
  error,
}: {
  nbrPlates: number
  formattedCost: string
  error: React.ReactNode
}) {
  const { t, langKeys } = useTranslate()

  return (
    <Box>
      <ConfirmationStepSummary>
        <ConfirmationStepSummaryRow>
          <LeftColumn>
            <MText variant="subtitleS">
              {nbrPlates} x {t(langKeys.mounting_plate_6_pack)}
            </MText>
          </LeftColumn>

          <RightColumn>
            <MText>{formattedCost}</MText>
          </RightColumn>
        </ConfirmationStepSummaryRow>

        <ConfirmationStepSummaryRow>
          <LeftColumn>
            <MText>{t(langKeys.shipping)}</MText>
          </LeftColumn>
          <RightColumn>
            <MText>{t(langKeys.free)}</MText>
          </RightColumn>
        </ConfirmationStepSummaryRow>

        <ConfirmationStepSummaryBill>
          <LeftColumn>
            <BilledNowColumn>
              <MText>{t(langKeys.billed_now)}</MText>
            </BilledNowColumn>
          </LeftColumn>

          <RightColumn>
            <LargeText>{formattedCost}</LargeText>
          </RightColumn>
        </ConfirmationStepSummaryBill>
      </ConfirmationStepSummary>

      <Show if={!!error}>
        <MBanner type="error" fullWidth>
          {error}
        </MBanner>
      </Show>
    </Box>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.M};
`

const ConfirmationStepSummary = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`

const LeftColumn = styled.div`
  display: flex;
  align-items: center;
`

const BilledNowColumn = styled(LeftColumn)`
  display: grid;
`

const RightColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`

const ConfirmationStepSummaryRow = styled.div`
  display: contents;
`

const ConfirmationStepSummaryBill = styled(ConfirmationStepSummaryRow)`
  & > * {
    margin-top: ${spacing.XL2};
  }
`

const LargeText = styled.div`
  font-size: 28px;
`
