import { useState } from "react"

import {
  IShippingAddress,
  TShippingField,
} from "src/components/order/MountingPlateStore/MountingPlateOrderWizard/shippingAddressTypes"
import { ShippingFields } from "src/components/order/MountingPlateStore/MountingPlateOrderWizard/ShippingFields"
import { useMountingPlateOrderContext } from "src/components/order/MountingPlateStore/useMountingPlateOrderContext"
import { useFlags } from "src/hooks/useFlags"
import { validatePhoneNumber } from "src/ui/PhoneNumberInput/phoneNumberUtil"

export function ShippingAddressStep({
  formId,
  onSubmit,
}: {
  formId: string
  onSubmit: (data: IShippingAddress) => void
}) {
  const { debug } = useFlags()
  const [phoneError, setPhoneError] = useState<boolean>()

  const { setShippingAddress, shippingAddress, resetStorage } =
    useMountingPlateOrderContext()

  function checkPhoneValid() {
    return shippingAddress?.phone && validatePhoneNumber(shippingAddress.phone)
  }

  function setField(field: TShippingField) {
    if (field.phone) {
      setPhoneError(false) // remove phone error on phone field change
    }
    if (field.country) {
      // reset state and state_code on country change
      const { state_code: _, state: __, ...rest } = shippingAddress ?? {}
      const updatedShippingAddress = { ...rest, ...field }
      setShippingAddress(updatedShippingAddress)
      return
    }
    setShippingAddress({ ...shippingAddress, ...field })
  }

  return (
    <form
      id={formId}
      onSubmit={(e) => {
        e.preventDefault()
        if (!shippingAddress) return

        if (!checkPhoneValid()) {
          setPhoneError(true)
          return // Prevent form submission
        }

        onSubmit(shippingAddress)
      }}
    >
      <ShippingFields
        shippingAddress={shippingAddress}
        setField={setField}
        phoneError={phoneError}
      />

      {debug && (
        <div style={{ margin: "2rem 0" }}>
          <button type="button" onClick={() => resetStorage()}>
            Clear stored data
          </button>
        </div>
      )}
    </form>
  )
}
