import { createContext } from "react"

import { ICustomer } from "src/components/Account/types"
import { IShippingAddress } from "src/components/order/MountingPlateStore/MountingPlateOrderWizard/shippingAddressTypes"
import { TSubscriptionCurrencyCode } from "src/data/billing/types/billingTypes"
import { TCountryDataCodes } from "src/data/countries/countryTypes"
import { Maybe } from "src/utils/tsUtil"

type ReactSetState<T> = React.Dispatch<React.SetStateAction<T>>

interface IMountingPlateOrderContext {
  shippingAddress: Maybe<IShippingAddress>
  setShippingAddress: ReactSetState<Maybe<IShippingAddress>>
  shippingCountry: Maybe<TCountryDataCodes>
  nbrPlates: number
  setNbrPlates: ReactSetState<number>
  loading: boolean
  customer: Maybe<ICustomer>
  formattedCost: string
  totalCost: number
  currencyCode: TSubscriptionCurrencyCode
  storeShippingAddress: () => void
  resetStorage: () => void
}
export const MountingPlateOrderContext = createContext(
  {} as IMountingPlateOrderContext
)
