import styled from "styled-components"

import { useMountingPlateOrderContext } from "src/components/order/MountingPlateStore/useMountingPlateOrderContext"
import { useTranslate } from "src/i18n/useTranslate"
import { backgroundGrayV2 } from "src/ui/colors"
import { Divider } from "src/ui/Divider/Divider"
import MetalPlate from "src/ui/icons/metal-plate.svg"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MNumberInput } from "src/ui/MNumberInput/MNumberInput"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function MountingPlateOrderAmount() {
  const { t, langKeys } = useTranslate()
  const { nbrPlates, setNbrPlates, formattedCost } =
    useMountingPlateOrderContext()

  return (
    <Box>
      <MText variant="heading2">Your order</MText>
      <OrderBox>
        <OrderGrid>
          <OrderRow>
            <MetalPlateOrder value={nbrPlates} setValue={setNbrPlates} />
            <MText variant="subtitle">{formattedCost}</MText>
          </OrderRow>

          <OrderRow>
            <MText variant="subtitle">{t(langKeys.shipping)}</MText>
            <MText variant="subtitle">{t(langKeys.free)}</MText>
          </OrderRow>

          <OrderDivider />

          <OrderRow>
            <MText variant="subtitle">{t(langKeys.billed_now)}</MText>
            <MText variant="subtitle">{formattedCost}</MText>
          </OrderRow>
        </OrderGrid>
      </OrderBox>

      <div>
        <ExternalLink href="https://support.minut.com/en/articles/1592124-shipping-countries-destinations-and-retailers">
          {t(langKeys.shipping_policy)}
        </ExternalLink>
      </div>
    </Box>
  )
}

function MetalPlateOrder({
  value,
  setValue,
}: {
  value: number
  setValue: (value: number) => void
}) {
  const { t, langKeys } = useTranslate()
  return (
    <MetalPlateOrderBox>
      <MetalPlateBox>
        <MetalPlate width={50} height={50} />
      </MetalPlateBox>

      <div>
        <MText variant="subtitle" marginBottom={spacing.XS2}>
          {value} x {t(langKeys.mounting_plate_6_pack)}
        </MText>
        <MNumberInput onChange={(v) => setValue(v)} value={value} min={1} />
      </div>
    </MetalPlateOrderBox>
  )
}

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.L};
`

const OrderBox = styled.div`
  border-radius: 8px;
  background: ${backgroundGrayV2};
`

const OrderGrid = styled.div`
  display: grid;
  gap: ${spacing.XS};
  grid-template-columns: auto auto;
  justify-content: space-between;
  padding: ${spacing.M};
`

const OrderRow = styled.div`
  display: contents;
`

const OrderDivider = styled(Divider)`
  grid-column: 1 / -1;
`

const MetalPlateOrderBox = styled.div`
  display: flex;
`

const MetalPlateBox = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-right: ${spacing.S};
  border-radius: 8px;
  background: white;
`
