import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import { RadioGroup } from "src/ui/RadioGroup/RadioGroup"

export function ShippingMethodStep() {
  const { t, langKeys } = useTranslate()

  return (
    <RadioGroup
      hideRadio
      value="free-shipping"
      compare={() => true}
      options={[
        {
          value: "free-shipping",
          render: () => (
            <Box>
              <div>{t(langKeys.standard)}</div>

              <div>{t(langKeys.free)}</div>
            </Box>
          ),
        },
      ]}
      onChange={() => {}}
    />
  )
}

const Box = styled.div`
  display: flex;
  justify-content: space-between;
`
