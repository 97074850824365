import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MDialog } from "src/ui/Dialog/MDialog"
import CheckMark from "src/ui/icons/checkmark-green.svg"
import { spacing } from "src/ui/spacing"

export function PlateStoreSuccessDialog({ open }: { open: boolean }) {
  const { navigate } = useRouter()
  const { t, langKeys } = useTranslate()

  if (!open) {
    return null
  }

  return (
    <MDialog
      // As per the design, we're intentionally disabling being able to close
      // this dialog.
      onClose={() => {}}
      onConfirm={() => navigate(Routes.Dashboard.location())}
      hideClose
      confirmLabel={t(langKeys.go_to_dashboard)}
      open={open}
      title={
        <DialogTitle>
          <CheckMark width={24} />
          {t(langKeys.mounting_plate_order_thanks)}
        </DialogTitle>
      }
      description={t(langKeys.mounting_plate_order_email_on_shipping)}
    />
  )
}

const DialogTitle = styled.div`
  display: flex;
  gap: ${spacing.XS};
  align-items: center;
`
