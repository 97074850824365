import { useMutation } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import {
  TPlateStorePostBody,
  TPlateStorePostResponse,
} from "src/data/plateStore/types/plateStoreTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function usePostMountingPlateOrder() {
  async function postMountingPlateOrder(body: TPlateStorePostBody) {
    const response = await minutApiHttpClient.post<TPlateStorePostBody>(
      `${API_DEFAULT}/product_order/mounting_plates`,
      body
    )

    return response.data
  }

  return useMutation<TPlateStorePostResponse, AxiosError, TPlateStorePostBody>(
    postMountingPlateOrder
  )
}
