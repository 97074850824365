import styled from "styled-components"

import { RoundProfilePicture } from "src/components/Account/Avatars"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { Routes } from "src/router/routes"
import Logo from "src/ui/icons/logo.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { SubtitleMixin } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function MountingPlateStoreTopBar() {
  const user = useGetUser()

  return (
    <Box>
      <StyledAppLink to={Routes.Dashboard.location()}>
        <Logo width={70} />
      </StyledAppLink>

      <ProfileInfo>
        <RoundProfilePicture />
        <div>
          <div>{user.fullname}</div>
        </div>
      </ProfileInfo>
    </Box>
  )
}

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: ${spacing.XL3};
`

const StyledAppLink = styled(InternalLink)`
  display: flex;
  place-content: center;
`

const ProfileInfo = styled.div`
  ${SubtitleMixin};
  line-height: 1;
  display: flex;
  place-items: center;
  gap: ${spacing.S};
`
